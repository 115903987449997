import React from "react"
import ReactMarkdown from "react-markdown"
import Gallery from '@browniebroke/gatsby-image-gallery'
import { localizePath } from "@/utils/localize"
import { Link } from "gatsby"
import Image from "./image"

const Blog = ({ blog, defaultLocale }) => {
  const labelData = (blog.locale !== 'mn') ? 
  {
    viewPost: "View Post",
    blogGrid: "Articles",
  } : {
    viewPost: "Нийтлэл харах",
    blogGrid: "Мэдээ"
  }
  return (
    <>
      <div className="container">
        <div className="wrapper-breadcrumbs">
          <div id="breadcrumbs">
            <Link to={localizePath({locale: blog.locale, defaultLocale: defaultLocale, isPreview: false, slug: "news" })}>{labelData.blogGrid}</Link>
            <i className="icon ion-android-arrow-forward separator"></i>
					  <span className="breadcrumb-last">{blog.title}</span>
          </div>
        </div>
      </div>

      <div className="container single-post-entry">
        <div className="row">
          <div className="col-lg-9">
            <section className="single-post-content">	
              <p className="post-category">{blog.blog_category && blog.blog_category.name}</p>
              <header className="single-post-header">
                <h1 className="post-title">{blog.title}</h1>
                <div className="post-info">
                  <p className="publish-day">{blog.published_at}</p>
                </div>
              </header>
              
              <div className="single-post-img">
                <img src={blog.image.localFile.publicURL} />
              </div>
              <div className="post-content">
                <ReactMarkdown>{blog.description}</ReactMarkdown>
              </div>
              <div className="soc-icons">
                
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default Blog
