import React from "react"
import { graphql } from "gatsby"
import Layout from "@/components/layout"
import SeoComp from "@/components/seo"
import Blog from "@/components/blog"

const BlogPage = ({ data, pageContext }) => {
  const blog = data.strapiBlog
  const { Seo, localizations } = data.strapiBlog
  const defaultLocale = data.site.siteMetadata.languages.defaultLocale
  const global = data.strapiGlobal
  
  return (
    <>
      <SeoComp seo={Seo} global={global} />
      <Layout global={global} pageContext={{ ...pageContext, localizations }}>
        <Blog blog={blog} defaultLocale={defaultLocale}/>
      </Layout>
    </>
  )
}

export default BlogPage


export const query = graphql`
  query BlogPageQuery($id: String!, $locale: String!) {
    site {
      siteMetadata {
        languages {
          defaultLocale
        }
      }
    }
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiBlog(id: { eq: $id }, locale: { eq: $locale}) {
      id
      strapiId
      title
      description
      slug
      blog_category {
        id
        name
        slug
      }
      image {
        alternativeText,
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 640
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
          publicURL
        }
      }
      Seo {
        id
        keywords
        mediaAlt
        media {
          id
          localFile {
            publicURL
          }
        }
        metaDescription
        metaTitle
        preventIndex
      }
      locale
      localizations {
        locale
        id
      }
    }
  }
`